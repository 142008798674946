
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  props: ["date"],
  setup(props) {
    const form = ref({});

    const farms = ref([]);
    const farm_id = ref(0);

    const farmName = computed(() => {
      const n: any = farms.value?.find((f: any) => f.id == farm_id.value);

      return n?.name;
    });

    function farm(id: any) {
      const n: any = farms.value?.find((f: any) => f.id == id);

      return n?.name;
    }

    const varieties = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const day = ref(0);
    const date = ref(new Date());

    const today = computed(() => new Date());

    const prod = ref(1);

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 4
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const s = props.date ? new Date(props.date) : wednesday();
    const start = ref(s);

    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    const masks = ref({
      input: "YYYY-MM-DD"
    });

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks() {
      const res = await http.get(
        `/api/forecasts?start=${fD(start.value, "yyyy-MM-dd")}&end=${fD(
          end.value,
          "yyyy-MM-dd"
        )}&type=pick`
      );

      return res.data.data;
    }

    function fetchVarieties() {
      processing.value = true;

      fetchPicks().then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: farms.value.reduce(
                (l: any, f: any) => ({
                  ...l,
                  [f.id]: ps.reduce(
                    (o: any, p: any) => ({
                      target:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.target
                          : 0,
                      projected:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.projected
                          : 0,
                      current:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.current
                          : 0,
                      comment:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.comment
                          : ""
                    }),
                    {}
                  )
                }),
                {}
              )
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function searchVarieties() {
      fetchPicks().then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}&fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: farms.value.reduce(
                (l: any, f: any) => ({
                  ...l,
                  [f.id]: ps.reduce(
                    (o: any, p: any) => ({
                      target:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.target
                          : 0,
                      projected:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.projected
                          : 0,
                      current:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.current
                          : 0,
                      comment:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.comment
                          : ""
                    }),
                    {}
                  )
                }),
                {}
              )
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function savePicks() {
      http
        .post("/api/forecasts", {
          farm_id: farm_id.value,
          picks: form.value,
          start: start.value,
          end: end.value
        })
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchVarieties();
        });
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setProd(n: 0) {
      prod.value = n;
    }

    function getSum(o: any, section: any) {
      const data = Object.values(o);

      return data.reduce((o: any, n: any) => {
        return o + parseFloat(n[section]);
      }, 0);
    }

    function getDifference(a: any, b: any) {
      return parseFloat(a) - parseFloat(b);
    }

    function getPercentage(a: any, b: any) {
      return b === 0 ? 0 : Math.round((a / b) * 100);
    }

    function isFilled(p: any) {
      const data = Object.entries(form.value);

      const x = data.filter((n: any) => {
        const f: any = (n[1] as any)[p];
        return Number(f?.projected) > 0;
      });

      return true; // x.length > 0;
    }

    onMounted(() => {
      fetchFarms();
      fetchVarieties();
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchVarieties();
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      savePicks,
      created,
      updated,
      fD,
      today,
      start,
      end,
      masks,
      getDates,
      setDay,
      day,
      form,
      farms,
      farmName,
      farm_id,
      prod,
      setProd,
      getSum,
      getPercentage,
      getDifference,
      isFilled
    };
  }
});
